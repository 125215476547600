import * as React from 'react'
import {
  mdiVuejs,
  mdiTailwind,
  mdiBulma,
  mdiNuxt,
  mdiLanguageHtml5,
  mdiLanguageCss3,
  mdiLaravel,
  mdiGithub,
  mdiLifebuoy,
  mdiCheck,
  mdiClose,
  mdiThemeLightDark,
  mdiChevronLeft,
  mdiMinus,
} from '@mdi/js'

const Icon = ({ icon, size = 24, wh = 'w-6 h-6', className = '' }) => {
  const icons = {
    mdiVuejs,
    mdiTailwind,
    mdiBulma,
    mdiNuxt,
    mdiLanguageHtml5,
    mdiLanguageCss3,
    mdiLaravel,
    mdiGithub,
    mdiLifebuoy,
    mdiCheck,
    mdiClose,
    mdiThemeLightDark,
    mdiChevronLeft,
    mdiMinus,
  }

  return (
    <span className={`inline-flex justify-center items-center ${wh} ${className}`}>
      <svg viewBox="0 0 24 24" width={size} height={size} className="inline-block">
        <path fill="currentColor" d={icons[icon] ?? icon} />
      </svg>
    </span>
  )
}

export default Icon
