import React from 'react'

const FaqAnswerUndelivered = () => (
  <>
    <p>There can be some delivery delays.</p>
    <p>
      You should get an e-mail with a link to the product within 30 minutes. Please make sure you've
      checked your Spam folder for our emails.
    </p>
    <p>
      If more than 30 minutes passed please contact us at{' '}
      <a href="mailto:viktor@justboil.me">viktor@justboil.me</a> and we will manually take care of
      your order.
    </p>
  </>
)

export default FaqAnswerUndelivered
