import React from 'react'
import { filter } from 'lodash'
import FaqAnswerUndelivered from './faqAnswerUndelivered'
import FaqAnswerUpgradeText from '../components/faqAnswerUpgradeText'
import { withinLine } from '../components/freeReturnsLine'

const FaqGitHubAnswer = ({ gitHubUrl, gitHubUrls }) => {
  if (gitHubUrls) {
    return (
      <>
        <p>Feel free to check free admin dashboard versions on GitHub:</p>
        <p>
          {gitHubUrls.map((url) => (
            <a href={url.url} key={url.url} target="_blank" rel="noreferrer" className="mx-2">
              {url.title}
            </a>
          ))}
        </p>
      </>
    )
  } else if (gitHubUrl) {
    return (
      <p>
        Feel free to check free admin dashboard version on{' '}
        <a href={gitHubUrl} rel="noreferrer" target="_blank">
          GitHub
        </a>
      </p>
    )
  }

  return (
    <p>
      Feel free to check free admin dashboard versions on{' '}
      <a href="https://github.com/vikdiesel" rel="noreferrer" target="_blank">
        GitHub
      </a>
    </p>
  )
}

const FaqPremiumOpenSourceAnswer = ({ title, url }) => (
  <>
    <p>Yes, since you add our link to your Readme.md:</p>
    <p>
      <em>
        Built with JustBoil {title} - {url}
      </em>
    </p>
  </>
)

export const FaqVatRefundsAnswer = () => (
  <>
    <p>Please fill your VAT ID at the invoice page, after your purchase is complete.</p>
    <p>
      In case of any questions, please drop an e-mail to{' '}
      <a href="mailto:viktor@justboil.me">viktor@justboil.me</a>.
    </p>
    <p>We'll automatically refund the VAT amount paid.</p>
  </>
)

const FaqItemInner = ({ question, id, children }) => (
  <>
    <h4 id={id}>{question}</h4>
    {children}
  </>
)

const FaqItem = ({ question, id, children }) => (
  <div className="max-w-4xl mx-auto px-6 md:px-12 xl:px-0 text-center mb-6 last:mb-0 pb-6 border-gray-200 border-b last:border-b-0 prose">
    <FaqItemInner id={id} question={question}>
      {children}
    </FaqItemInner>
  </div>
)

const FaqItems = ({
  item: { isFree, slug, gitHubUrl, gitHubUrls, title },
  url,
  faqPage = false,
}) => {
  const items = [
    {
      id: 'backend',
      question: 'Can I integrate this dashboard with backend of my choice?',
      answer: <p>Yes. You are free to use any backend with this dashboard.</p>,
      freeRelated: true,
      backendRelated: true,
    },
    {
      id: 'code-quality',
      question: 'Are your dashboards well-coded?',
      answer: <FaqGitHubAnswer gitHubUrl={gitHubUrl} gitHubUrls={gitHubUrls} />,
    },
    {
      id: 'product-returns',
      question: "May I return the dashboard if I don't think I'm satisfied?",
      answer: <p>Yes, you can check a purchased item and request a refund {withinLine}</p>,
    },
    {
      id: 'license-upgrade',
      question: 'Can I buy Standard License and upgrade it to Extended later?',
      answer: <FaqAnswerUpgradeText />,
      upgradeText: true,
    },
    {
      id: 'difference-free-premium',
      question: 'What is the difference between free and premium dashboard versions?',
      answer: (
        <p>
          Free items are simple dashboards with basic stuff. Premium versions are more advanced.
          Feel free to check the demos!
        </p>
      ),
      freeRelated: true,
    },
    {
      id: 'copyrights',
      question: 'Do I have to keep your copyrights?',
      answer: (
        <p>
          Free items are licensed under MIT, so you have to keep our copyrights. Premium items allow
          you to remove copyrights.
        </p>
      ),
      freeRelated: true,
    },
    {
      id: 'premium-open-source',
      question: 'Can I use premium dashboard in an open-source project?',
      answer: <FaqPremiumOpenSourceAnswer title={title} url={url} />,
    },
    {
      id: 'didnt-receive',
      question: "What I have to do if I've paid but didn't receive the product?",
      answer: <FaqAnswerUndelivered />,
      faqPageOnly: true,
    },
    {
      id: 'doesnt-work',
      question: "What if purchased product doesn't work?",
      answer: (
        <>
          <p>
            First of all, JustBoil's products work. I have tested them, hundreds of people before
            had already used them.
          </p>
          <p>
            There may be a bug or something missing in the process you are undergoing, so please
            contact me to get to the bottom of it.
          </p>
          <p>
            Send an e-mail to <a href="mailto:viktor@justboil.me">viktor@justboil.me</a> and I'll
            get back to you, within the next 24-48 hours (business days only).
          </p>
        </>
      ),
      faqPageOnly: true,
    },
    {
      id: 'usage',
      question: "What if I don't know how to use the product?",
      answer: (
        <>
          <p>
            To be able to use JustBoil's products, you need minimum knowledge of web development and
            front-end.
          </p>
          <p>
            Check related framework's tutorials. Also, there's a Readme, on where to start, if you
            are at the beginning.
          </p>
        </>
      ),
      faqPageOnly: true,
    },
    {
      id: 'vat-refunds',
      question: "I'm not required to pay VAT",
      answer: <FaqVatRefundsAnswer />,
    },
  ]

  const newItems = faqPage
    ? items
    : filter(items, (item) => {
        if (item.faqPageOnly) {
          return false
        }

        if (slug === 'free-laravel-dashboard') {
          return !item.backendRelated && item.freeRelated
        }

        return isFree ? item.freeRelated : true
      })

  return (
    <>
      {newItems.map((item) => (
        <FaqItem key={item.id} question={item.question} id={item.id}>
          {item.answer}
        </FaqItem>
      ))}
    </>
  )
}
export default FaqItems
