import React from 'react'
import FaqItems from '../components/faqItems'

const FaqPageItems = () => {
  const dummyItem = {
    isFree: null,
    slug: null,
    gitHubUrl: null,
    gitHubUrls: null,
    title: 'templateTitle',
  }

  return <FaqItems item={dummyItem} faqPage={true} url="templateUrl (example)" />
}

export default FaqPageItems
