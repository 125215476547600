exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-failure-js": () => import("./../../../src/pages/failure.js" /* webpackChunkName: "component---src-pages-failure-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-buy-js": () => import("./../../../src/templates/buy.js" /* webpackChunkName: "component---src-templates-buy-js" */),
  "component---src-templates-filter-js": () => import("./../../../src/templates/filter.js" /* webpackChunkName: "component---src-templates-filter-js" */),
  "component---src-templates-info-page-js": () => import("./../../../src/templates/infoPage.js" /* webpackChunkName: "component---src-templates-info-page-js" */),
  "component---src-templates-structure-js": () => import("./../../../src/templates/structure.js" /* webpackChunkName: "component---src-templates-structure-js" */),
  "component---src-templates-template-js": () => import("./../../../src/templates/template.js" /* webpackChunkName: "component---src-templates-template-js" */)
}

