import React from 'react'

export const withinLine = 'within 24 hours (14 days on Extended license)'

const FreeReturnsLine = () => (
  <>
    <b>Free returns</b> {withinLine}
  </>
)

export default FreeReturnsLine
