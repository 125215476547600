import React from 'react'

const FaqAnswerUpgradeText = () => (
  <>
    <p>You can upgrade license whenever you want by just paying the difference.</p>
    <p>
      Drop an email to <a href="mailto:viktor@justboil.me">viktor@justboil.me</a>
    </p>
  </>
)

export default FaqAnswerUpgradeText
